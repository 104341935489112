const moduleMap = {
  cs313: 'CS313 Mobile Robotics',
  cs324: 'CS324 Computer Graphics',
  cs342: 'CS342 Machine Learning',

  px277: 'PX277 Computational',
  px350: 'PX350 Weather',
  px382: 'PX382 Quantum',
  px385: 'PX385 Condensed Matter',
  px397: 'PX397 Galaxies',
  px308: 'PX308 Medicine',
    
  pcomm: 'Communicating',
  pproj: 'Project',

}
const types = {
  lecture: 'Lecture',
  workshop: 'Workshop',
  seminar: 'Seminar',
}

const e = (module, room, type) => ({
  name: moduleMap[module],
  room,
  type
})

export default {
  monday: {
    theo: [
      null, // 9am
      e('cs324', 'CS0.06', types.workshop), // 10am
      e('cs324', 'CS0.06', types.workshop), // 11am
      null, // 12pm
      null, // 1pm
      e('cs324', 'CS1.04', types.lecture), // 2pm
      null, // 3pm
      null, // 4pm
      e('cs342', 'OC0.03', types.lecture), // 5pm
    ],
    george: [
      null, // 9am
      e('cs324', 'CS0.06', types.workshop), // 10am
      e('cs324', 'CS0.06', types.workshop), // 11am
      null, // 12pm
      null, // 1pm
      e('cs324', 'CS1.04', types.lecture), // 2pm
      null, // 3pm
      null, // 4pm
      e('cs342', 'OC0.03', types.lecture), // 5pm
    ],
    will: [
      null, // 9am
      e('px385', 'H0.51', types.lecture), // 10am
      null, // 11am
      null, // 12pm
      e('px277', 'H0.51', types.lecture), // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      e('px397', 'S0.21', types.lecture), // 5pm
    ],
    haydn: [
      null, // 9am
      e('px385', 'H0.51', types.lecture), // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      e('px397', 'S0.21', types.lecture), // 5pm
    ],
  },
  tuesday: {
    theo: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      e('cs342', 'CS1.04', types.seminar), // 2pm
      e('cs313', 'L4', types.lecture), // 3pm
      null, // 4pm
      null, // 5pm
    ],
    george: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      e('cs342', 'CS1.04', types.seminar), // 2pm
      e('cs313', 'L4', types.lecture), // 3pm
      null, // 4pm
      null, // 5pm
    ],
    will: [
      null, // 9am
      e('px308', 'H0.52', types.lecture), // 10am
      null, // 11am
      e('px308', 'L4', types.lecture), // 12pm
      e('px385', 'PLT', types.lecture), // 1pm
      null, // 2pm
      e('pcomm', 'MAS2.03', types.seminar), // 3pm
      e('pcomm', 'MAS2.03', types.seminar), // 4pm
      null, // 5pm
    ],
    haydn: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      e('px385', 'PLT', types.lecture), // 1pm
      null, // 2pm
      e('pcomm', 'MAS2.03', types.seminar), // 3pm
      e('pcomm', 'MAS2.03', types.seminar), // 4pm
      null, // 5pm
    ],
  },
  wednesday: {
    theo: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
    george: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
    will: [
      null, // 9am
      e('px397', 'L4', types.lecture), // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
    haydn: [
      null, // 9am
      e('px397', 'L4', types.lecture), // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
  },
  thursday: {
    theo: [
      e('cs342', 'CS0.03', types.workshop), // 9am
      e('cs342', 'CS0.03', types.workshop), // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      e('cs324', 'L5', types.lecture), // 2pm
      e('cs342', 'OC0.03', types.lecture), // 3pm
      null, // 4pm
      null, // 5pm
    ],
    george: [
      e('cs342', 'CS0.03', types.workshop), // 9am
      e('cs342', 'CS0.03', types.workshop), // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      e('cs324', 'L5', types.lecture), // 2pm
      e('cs342', 'OC0.03', types.lecture), // 3pm
      null, // 4pm
      null, // 5pm
    ],
    will: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
    haydn: [
      null, // 9am
      null, // 10am
      null, // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
  },
  friday: {
    theo: [
      null, // 9am
      null, // 10am
      null, // 11am
      e('cs313', 'CS0.03', types.workshop), // 12pm
      e('cs313', 'CS0.03', types.workshop), // 1pm
      null, // 2pm
      null, // 3pm
      e('cs342', 'Woods-Scawen', types.lecture), // 4pm
      e('cs324', 'L5', types.lecture), // 5pm
    ],
    george: [
      null, // 9am
      null, // 10am
      null, // 11am
      e('cs313', 'CS0.03', types.workshop), // 12pm
      e('cs313', 'CS0.03', types.workshop), // 1pm
      null, // 2pm
      null, // 3pm
      e('cs342', 'Woods-Scawen', types.lecture), // 4pm
      e('cs324', 'L5', types.lecture), // 5pm
    ],
    will: [
      null, // 9am
      e('px397', 'H0.51', types.lecture), // 10am
      e('px385', 'PLT', types.lecture), // 11am
      null, // 12pm
      e('px277', 'A0.02', types.lecture), // 1pm
      e('px308', 'PLT', types.lecture), // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
    haydn: [
      null, // 9am
      e('px397', 'H0.51', types.lecture), // 10am
      e('px385', 'PLT', types.lecture), // 11am
      null, // 12pm
      null, // 1pm
      null, // 2pm
      null, // 3pm
      null, // 4pm
      null, // 5pm
    ],
  },
}
