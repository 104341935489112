import React, { Component } from 'react'
import Timetable from './Timetable'
import ttdata from './timetable-data'

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']

export default class extends Component {
  constructor(props) {
      super(props)

    let day = ((new Date().getDay() - 1) % days.length)

    day = day >= 0 ? day : 0

    this.state = {
      day
    }

    this.keyDown = this.keyDown.bind(this)
    this.nextDay = this.nextDay.bind(this)
    this.prevDay = this.prevDay.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown)
  }

  keyDown(e) {
    if (e.keyCode === 37) this.prevDay()
    if (e.keyCode === 39) this.nextDay()
  }

  nextDay() {
    this.setState(prevState => ({ day: (prevState.day + 1) % days.length }))
  }
  prevDay() {
    this.setState(prevState => ({ day: (prevState.day === 0) ? days.length - 1 : prevState.day - 1 }))
  }

  render() {
    const tt = ttdata[days[this.state.day]]

    return <Timetable day={days[this.state.day]} next={this.nextDay} prev={this.prevDay} {...tt} />
  }
}
