import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: white;
  box-shadow: 1mm 1mm 5mm rgba(100,100,100,0.1);
  border-radius: 2mm;
  width: 100%;
  margin: 0 auto;

  position: relative;

  padding: 1mm 3mm;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
`
const Name = styled.p`
  margin: 0;
  padding: 0;

  font-size: 4mm;
`
const Details = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`
const Location = styled.p`
  margin: 0;
  padding: 0;

  font-size: 3mm;
`
const Type = styled.p`
  margin: 0;
  padding: 0;

  font-size: 3mm;

  text-align: right;
`

export default ({ name, room, type, span }) => (
  <Container style={{gridRowEnd: `span ${span}`}}>
    <Name>{name}</Name>
    <Details>
      <Location>{room}</Location>
      <Type>{type}</Type>
    </Details>
  </Container>
)
