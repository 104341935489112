import React from 'react'
import styled from 'styled-components'

import DayLabel from './DayLabel'
import TimeLabels from './TimeLabels'
import PersonTimetable from './PersonTimetable'

const Timetable = styled.div`
  width: 297mm;
  height: 210mm;
  padding: 10mm;
  box-sizing: border-box;
  background-color: #EFEFEF;
  border-radius: 2mm;
  box-shadow: 5mm 5mm 10mm rgba(100,100,100,0.1);

  margin: 10mm auto;

  display: grid;
  grid-template-columns: 15mm 15mm repeat(4, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 3mm;
`
const DLs = styled(DayLabel)`
  grid-column: 1;
  grid-row: 1 / span 10;
`
const TLs = styled(TimeLabels)`
  grid-row: 2 / span 9;
  grid-column: 2;
`
const PTs = styled(PersonTimetable)`
  grid-row: 1 / span 10;
`

export default ({ day, theo, george, will, haydn, next, prev }) => (
   <Timetable>
    <DLs day={day} next={next} prev={prev} />
    <TLs />

    <PTs style={{gridColumn: 3}} name="Theo" events={theo} />
    <PTs style={{gridColumn: 4}} name="George" events={george} />
    <PTs style={{gridColumn: 5}} name="Will" events={will} />
    <PTs style={{gridColumn: 6}} name="Haydn" events={haydn} />
  </Timetable>
)
