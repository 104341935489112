import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(9, 1fr);
`
const Time = styled.p`
  text-align: right;
  height: 0;
  margin: 0;
  padding: 0;

  font-size: 3mm;
`

const times = ['9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm']

export default ({ ...rest }) => (
  <Container {...rest}>
    {times.map(t => <Time key={t}>{t}</Time>)}
  </Container>
)
