import React from 'react'
import styled from 'styled-components'

const Day = styled.h1`
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-align: center;
  text-transform: capitalize;

  margin: 0;
  padding: 0;
`
const Button = styled.span`
  padding: 2vh 0;
  cursor: pointer;
  opacity: 0.1;
  transition: opacity 0.1s;

  &:hover {
    opacity: initial;
  }
`

export default ({ day, prev, next, ...rest }) => (
  <Day {...rest}>
    <Button onClick={prev}>&lt;</Button>
    {day}
    <Button onClick={next}>&gt;</Button>
  </Day>
)
