import React from 'react'
import styled from 'styled-components'

import Name from './Name'
import Event from './Event'

const Container = styled.div`
  width: calc(100% - 2mm);
  margin: 0 auto;

  grid-row-gap: 2mm;

  display: grid;
  grid-template-rows: repeat(10, 1fr);
`
const StyledName = styled(Name)`
  grid-row: 1;
`

const eventEq = (a, b) => a.name === b.name && a.room === b.room && a.type === b.type

export default ({ name, events, ...rest }) => {
  for (let i = 0; i < events.length; i++) {
    for (let j = i+1; j < events.length; j++) {
      if (events[i] && events[j] && eventEq(events[i], events[j])) {
        events[i].span = events[i].span ? events[i].span+1 : 2
        events[j] = 'span'
      } else {
        break
      }
    }
  }

  return <Container {...rest}>
    <StyledName>{name}</StyledName>

    {events.map((e, i) => {
      if (!e) return <div key={i} />
      if (e === 'span') return null
      return <Event key={i} {...e} />
    })}
  </Container>
}
